.container-md {
  display: flex;
  width: 100%;
  max-width: 1848px;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 auto;
}

.sidebar-user {
  display: block;
  width: 396px;
  position: relative;
}

.sidebar-user .user-info {
  border-radius: 25px;
  background-color: white;
  position: relative;
  min-height: 300px;
  height: auto;
  margin-bottom: 20px;
}

.user-info {
  padding: 10px 0 0 0;
  margin: 0;
}

.user-info li {
  list-style: none;
  width: 320px;
  position: relative;
  height: 32px;
  font-size: 16px;
}

.user-info li::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background-color: #2a2baa;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 30px 0 65px;
}

.user-info li:first-child {
  background-color: #f0f2f5;
  height: 99px;
  /* line-height: 99px; */
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-break: auto;
  text-align-last: center;
}

.user-info li:first-child::before {
  content: '';
  background-color: #1199f7;
  width: 60px;
  height: 60px;
  margin: 0 13px 0 9px;
}

.user-info li:first-child::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 42px 42px;
  border-color: transparent transparent white transparent;
  position: absolute;
  bottom: 0;
  right: 0;
}

.user-info .userInfoList {
  margin-bottom: 20px;
}

.center-page {
  width: 100%;
  max-width: 1000px;
  flex: 5;
  margin-left: 20px;
  margin-right: 20px;
}

.neo-score {
  border-radius: 25px;
  background-color: white;
  position: relative;
}

.neo-score h1 {
  padding: 0;
  margin: 0 0 20px;
  font-size: 58px;
  line-height: 64px;
  font-weight: normal;
}

.neo-score .neoLogo img {
  width: 100%;
  max-width: 400px;
}

.neo-score .flex {
  margin: 10px 40px 0;
  align-items: center;
  justify-content: center;
}

/* .neo-score .neoLogo {
    flex-basis: 60%;
} */

.neo-score .text-items {
  flex-basis: 40%;
  color: #0a2365;
  text-align: center;
  position: relative;
}

.neo-score .text-items h1 {
  font-size: 60px;
  font-weight: 600;
}

.neo-score .text-items b {
  font-size: 66px;
}

.home-chartdiv {
  position: relative;
  top: -40px;
}

.user-results {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  background-color: white;
  position: relative;
  margin: 20px 0;
  padding: 20px 40px;
}

.user-results .results-time h3 {
  color: #c80136;
  font-size: 28px;
  padding: 0;
  margin: 0 0 15px 0;
  font-weight: 800;
}

.user-results .results-time time {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.user-results .overview-link a {
  color: #c80136;
  text-decoration: underline;
  font-size: 24px;
}

.info-details.flex {
  flex-wrap: wrap;
  gap: 20px;
  flex: 1;
  max-width: 207px;
  width: 100%;
  height: 100%;
}

.info-details.flex .module1,
.info-details.flex .module2,
.info-details.flex .module3 {
  width: 207px;
  border-radius: 25px;
  background-color: white;
  position: relative;
  padding: 10px 7px 18px 7px;
}
.info-details.flex .module1.bestList,
.info-details.flex .module2.bestList,
.info-details.flex .module3.bestList {
  width: 207px;
  border-radius: 25px;
  background-color: white;
  position: relative;
  padding: 12px 7px 5px;
}
.info-details.flex .module1.bestList h4,
.info-details.flex .module2.bestList h4,
.info-details.flex .module3.bestList h4 {
  color: #0a2365;
  font-size: 16px;
  margin: 6px 0;
  font-weight: bold;
}
.info-details.flex .module1.bestList ul,
.info-details.flex .module2.bestList ul,
.info-details.flex .module3.bestList ul {
  list-style: circle !important;
  margin: 4px 0;
}
.info-details.flex .module1.bestList ul li,
.info-details.flex .module2.bestList ul li,
.info-details.flex .module3.bestList ul li {
  display: flex;
  justify-content: space-between;
  list-style: circle;
}
.moduleAll {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.info-details.flex .module1 h3,
.info-details.flex .module2 h3,
.info-details.flex .module3 h3 {
  position: relative;
  height: 50px;
  font-size: 22px;
  padding: 0;
  font-weight: 700;
  margin: 14px 0 0px;
  line-height: 50px;
  vertical-align: middle;
  cursor: pointer;
  color: #000;
}

.info-details.flex .module1.bestList h3,
.info-details.flex .module2.bestList h3,
.info-details.flex .module3.bestList h3 {
  position: relative;
  height: 50px;
  font-size: 22px;
  padding: 0;
  font-weight: 700;
  margin: 2px 0 !important;
  line-height: 50px;
  vertical-align: middle;
  cursor: pointer;
  color: #000;
}

.info-details.flex .module1 h3::before,
.info-details.flex .module2 h3::before,
.info-details.flex .module3 h3::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}

/* .info-details.flex .module1 h3::before {
  background-color: rgb(146, 158, 168);
}

.info-details.flex .module2 h3::before {
  background-color: rgb(0, 153, 247);
}

.info-details.flex .module3 h3::before {
  background-color: #de2348;
} */

.info-details.flex .module1 h3 span,
.info-details.flex .module2 h3 span,
.info-details.flex .module3 h3 span {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  right: 0;
  display: contents;
}

/* .info-details.flex .module1 h3 span::before,
.info-details.flex .module2 h3 span::before,
.info-details.flex .module3 h3 span::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent #000000 transparent;
  position: relative;
  top: -15px;
  left: -3px;
} */

.info-details.flex .module1 .score,
.info-details.flex .module2 .score,
.info-details.flex .module3 .score {
  font-size: 44px;
}

.info-details.flex .module1 .max,
.info-details.flex .module2 .max,
.info-details.flex .module3 .max {
  font-size: 30px;
  font-weight: 500;
}

/* .info-details.flex .module1 .score {
  color: #46c5e9;
}

.info-details.flex .module2 .score {
  color: #657079;
}

.info-details.flex .module3 .score {
  color: #de2348;
  font-weight: 500;
} */

.info-details.flex .module1 svg,
.info-details.flex .module2 svg,
.info-details.flex .module3 svg {
  margin-top: 22px;
}

.barChart {
  max-height: 84px;
  max-width: 158px;
  margin: 0 auto;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .info-details.flex {
    flex-wrap: wrap;
  }
}
@media (max-width: 1280px) {
  .info-details.flex {
    flex-wrap: wrap;
  }
  .sidebar-user {
    margin: 0;
  }
  .info-details.flex {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .neo-score .flex {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container-md {
    flex-wrap: wrap;
  }
}
