.appointment-list .container {
    max-width: 1920px;
}

.d-flex {
    display: flex;
}

.wrap {
    flex-wrap: wrap;
}

img#logo {
    position: relative;
    float: right;
}

.sideBar {
    max-width: 300px;
    width: 100%;
}

.sideBar .sideBar-items .b-cog {
    border: solid #696969;
    border-width: 0 0 0 6px;
}

.title-items.d-flex {
    justify-content: space-between;
    align-items: center;
}

.page-title {
    text-align: center;
    flex: 3;
}

.page-title h1 {
    font-size: 36px;
    color: #000000;
    font-weight: 600;
}

.date span {
    font-size: 32px;
    color: #000000;
}

.clock {
    margin-bottom: 30px;
}

.clock span {
    font-size: 62px;
    color: #C80036;
}

.location .image img {
    max-width: 80px;
}

.location .location-infos {
    align-self: center;
    margin-left: 5px;
}

.location .location-infos span {
    display: block;
    font-size: 26px;
    color: #000000;
}

.location .location-infos small {
    font-size: 20px;
    color: #010001;
}

.appointment-items {
    padding-top: 20px;
    row-gap: 60px;
}

.appointment-item-card {
    background-color: #ffffff;
    padding: 24px 16px;
    width: 100%;
    max-width: calc(100% - 5rem);
    border-radius: 25px;
    border-left-width: 10px;
    border-left-style: solid;
    border-left-color: #EC0044;
    height: auto;
}

.appointment-item-card:nth-child(2) {
    border-left-color: #002365;
}

.appointment-item-card:nth-child(3) {
    border-left-color: #929EA8;
}

.appointment-item-card .title {
    font-size: 16px;
    color: #000000;
}

.appointment-item-card .content {
    margin-top: 8px;
}

.appointment-item-card .content span {
    font-size: 20px;
    color: #000000;
    display: block;
}

.patients-name {
    flex: 1;
}

.idNumber {
    flex: 1;
}

.appointment-time {
    flex: 1;
}

.hubs {
    flex: 2;
}

.hubs-item {
    text-align: center;
}

.content.d-flex {
    justify-content: space-between;
}

.b-emo {
    border: solid #C80036;
    border-width: 0 0 0 6px;
}

.b-phy {
    border: solid #003471;
    border-width: 0 0 0 6px;
}

.c-cog {
    color: #707070;
}

.c-emo {
    color: #781E37;
}

.c-phy {
    color: #1B304D;
}

.cognitive {
    font-size: 28px;
    margin-top: 10px;
}

.emotional {
    font-size: 28px;
    margin-top: 10px;
}

.physical {
    font-size: 28px;
    margin-top: 10px;
}

@media (max-width: 1600px) {
    .appointment-list .container {
        width: 100% !important;
    }
}

@media (max-width:1440px) {
    .date span {
        font-size: 32px;
        color: #000000;
    }
    .clock span {
        font-size: 58px;
        color: #C80036;
    }
    .location .location-infos span {
        display: block;
        font-size: 24px;
        color: #000000;
    }
    .location .location-infos small {
        font-size: 18px;
        color: #010001;
    }
    .appointment-item-card .title {
        font-size: 15px;
        color: #000000;
    }
    .appointment-item-card .content span {
        font-size: 18px;
        color: #000000;
        display: block;
    }
    .appointment-item-card {
        max-width: 100%;
    }
}