.container {
  display: flex;
  width: calc(100% - 4rem);
  margin: 0 auto;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.container.xl {
  max-width: calc(100% - 3rem);
  width: 100%;
}

.sidebar {
  display: block;
  max-width: 280px;
  width: 100%;
}

.sidebar.short {
  max-width: 320px;
}

.sidebar .user-infos {
  margin-bottom: 7px;
}

.user-infos > .user-info,
.user-infos > ul,
.user-infos {
  border-radius: 25px;
  background-color: white;
  position: relative;
  padding: 5px 0 5px 0;
  height: auto;
}
.user-infos h1 {
  color: #0a2365;
  font-size: 22px;
  margin-left: 14px;
}

.user-infos.second {
  margin-top: 20px;
}
.user-infos img {
  position: absolute;
  max-width: 95px;
  top: 81px;
  left: 94px;
}
.reviews {
  /* border-radius: 25px; */
  background-color: white;
  position: relative;
  padding: 15px 10px 5px 10px;
  width: 100%;
  /* max-width: 1100px; */
  flex: 1;
  height: 100%;
  height: 730px;
  max-height: 834px;
  border-radius: 25px;
}

.reviews p {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 15px;
}

.container.xl.user-infos > [role='tree'] {
  padding-left: 0px;
  position: relative;
  margin: 0 auto;
}

.container .user-infos > [role='tree'] {
  padding-left: 30px;
  position: relative;
  margin: 0 auto;
}

.MuiTreeItem-label {
  font-size: 18px;
  font-weight: 500;
}

body {
  font-size: 20px;
  font-weight: 500;
}

.userName {
  font-weight: bold !important;
}

.MuiBox-root-32 {
  width: 100%;
  flex: 1;
}
