.container-physical-module {
    padding: 20px 50px;
}

ul {
    list-style: none;
    padding-left: 0;
}

.domainName {
    font-size: 20px;
    font-weight: 600;
}

.domainList {
    /* display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    /* grid-template-columns: repeat(4, 1fr); */
    /* column-gap: 10px; */
    /* display: flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    column-gap: 70px;
}

.domainList>* {
    flex: 1 280px;
}

.flexItems li {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    list-style-position: inside;
}

.flexItems {
    line-height: 2.8;
    display: flex;
    list-style-position: inside;
    justify-content: space-between;
    /* margin-left: 5px; */
    /* flex: 1 0 300px; */
}

.flexItems .metricResult {
    font-weight: 600 !important;
}