@media (max-width: 768px) {
  .MuiGrid-item.card-block {
    flex-basis: 43% !important;
  }
}

.p-column-title {
  font-weight: bold;
  color: #000;
}

td.result,
td.resultUnit {
  justify-content: flex-end;
}

td.referenceValue {
  justify-content: center;
}

.p-filter-column {
  padding: 1rem 0 !important;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  display: none;
}

.p-datatable .p-column-header-content {
  justify-content: center;
}

.actionBody {
  padding: 1rem 0rem !important;
}

.p-button {
  padding: 0.5rem 0.5rem !important;
}
.MuiOutlinedInput-root.MuiInputBase-root {
  padding: 1px !important;
}
.MuiInputLabel-root.MuiInputLabel-formControl {
  top: -5px !important;
}

.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  top: 0 !important;
}
.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}
