.emotional {
  display: block;
  position: relative;
  border-radius: 25px;
  background-color: white;
}

.emotionalTabs {
  padding: 20px;
}

.emotionalTabs p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

/*[role='tabpanel'] {*/

/*    max-width: 500px;*/

/*}*/

.report {
  display: grid;
  grid-template-columns: 460px repeat(3, 320px);
  grid-template-rows: 360px 155px 410px 0 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.report > div,
.report > ul {
  border-radius: 25px;
  background-color: white;
  position: relative;
}

.neo-auvra-score {
  grid-area: 1 / 2 / 2 / 5;
  text-align: center;
  font-weight: 500;
  font-size: 62px;
}

.emotional {
  grid-area: 3/1;
}

.neo-auvra-score h1 {
  padding: 0;
  margin: 0 0 20px;
  font-size: 58px;
  line-height: 64px;
  font-weight: normal;
}

.explanation {
  grid-area: 2 / 1 / 4 / 2;
  padding: 0 50px;
}

.explanation h3 {
  font-size: 32px;
  margin: 70px 0 50px 0;
}

.explanation p {
  font-size: 20px;
  padding: 0;
  margin: 0;
}

.explanation ul {
  padding: 0 0 0 74px;
  margin: 80px 0 0 0;
}

.explanation li {
  list-style: none;
  width: 395px;
  position: relative;
  margin-bottom: 13px;
  height: 32px;
  font-size: 16px;
}

.explanation li::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background-color: #2a2baa;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.explanation li:nth-child(1):before {
  background-color: #929ea8;
}

.explanation li:nth-child(2):before {
  background-color: #003471;
}

.explanation li:nth-child(3):before {
  background-color: #790000;
}

.personal-results {
  grid-area: 2 / 2 / 3 / 5;
  padding: 25px 60px;
}

.personal-results h3 {
  color: #c80136;
  font-size: 26px;
  padding: 0;
  margin: 0 0 15px 0;
}

.personal-results a {
  color: #c80136;
  text-decoration: underline;
  top: 60px;
  right: 80px;
  position: absolute;
  font-size: 22px;
}

.personal-results time {
  font-size: 16px;
}

/* .module1 {
    grid-area: 3 / 2 / 4 / 3;
    padding: 55px 25px 55px 37px;
}

.module2 {
    grid-area: 3 / 3 / 4 / 4;
    padding: 55px 25px 55px 37px;
}

.module3 {
    grid-area: 3 / 4 / 4 / 5;
    padding: 55px 25px 55px 37px;
} */

.module1 h3,
.module2 h3,
.module3 h3 {
  position: relative;
  height: 50px;
  font-size: 24px;
  padding: 0;
  font-weight: 500;
  margin: 0 0 60px;
  line-height: 50px;
  vertical-align: middle;
  cursor: pointer;
}

/* .module1 h3::before {
  background-color: #929ea8;
}

.module2 h3::before {
  background-color: #003471;
}

.module3 h3::before {
  background-color: #790000;
} */

.module1 h3 span,
.module2 h3 span,
.module3 h3 span {
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.module1 h3 span::before,
.module2 h3 span::before,
.module3 h3 span::before {
  /* content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 9px 6px;
    border-color: transparent transparent #000000 transparent;
    position: relative;
    top: -15px;
    left: -3px; */
}

.module1 .score,
.module2 .score,
.module3 .score {
  font-size: 60px;
}

.module1 .max,
.module2 .max,
.module3 .max {
  font-size: 30px;
  font-weight: 500;
}

.module1 .score {
  color: #929ea8;
}

.module2 .score {
  color: #003471;
}

.module3 .score {
  color: #790000;
  font-weight: 500;
}

.module1 svg,
.module2 svg,
.module3 svg {
  margin-top: 80px;
}

.detail {
  grid-area: 5 / 1 / 6 / 5;
  padding: 67px 50px;
  font-family: 'Neutro';
  font-weight: 500;
  font-size: 23px;
}

.detail h3 {
  font-size: 28px;
}

.domains {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-column-gap: 60px;
  margin-top: 50px;
  margin-left: 130px;
}

.domains ul {
  padding: 0;
  margin: 0;
}

.domains li {
  list-style: none;
  line-height: 175%;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.domains .selected {
  color: #003471;
}

.cognitive .domains .selected {
  color: #fe0036;
}

.physical .domains .selected {
  color: #003471;
}

.emotional .domains .selected {
  color: #790000;
}

.overview {
  background-color: white;
  display: grid;
  grid-template-columns: 660px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  border-radius: 25px;
  width: 1145px;
}

.overview h3 {
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  padding: 0;
}

.overview h4 {
  font-weight: 600;
  font-size: 23px;
  margin: 0;
  padding: 0;
}

.overview .results {
  padding: 25px;
}

.overview .result {
  font-weight: 300;
  font-size: 20px;
}

.overview ul {
  padding: 0 0 0 30px;
  margin: 20px 0 0 0;
}

.overview li {
  list-style: none;
  width: 395px;
  position: relative;
  margin-bottom: 13px;
  height: 25px;
  font-size: 15px;
  cursor: pointer;
}

.overview li::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background-color: #2a2baa;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.overview li:nth-child(1):before {
  background-color: #929ea8;
}

.overview li:nth-child(2):before {
  background-color: #003471;
}

.overview li:nth-child(3):before {
  background-color: #790000;
}

.neo-auvra-score .neoLogo {
  flex-basis: 60%;
}

.neo-auvra-score .neoLogo img {
  width: 100%;
}

.neo-auvra-score .flex {
  margin: 100px 40px;
}

.neo-auvra-score .text-items {
  flex-basis: 40%;
  color: #0a2365;
}

.neo-auvra-score .text-items h1 {
  font-size: 70px;
  font-weight: 600;
}

.border_none .p-dropdown {
  border: none !important;
}
.p-tabview-nav {
  background: transparent !important;
}

.p-tabview .p-tabview-panels {
  background: transparent !important;
  padding: 0.5rem 0.1rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent !important;
}
