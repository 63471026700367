.makeStyles-toolbarIcon-18 {
    justify-content: center !important;
}

.makeStyles-menuButton-7 {
    margin-right: 0 !important;
}

.MuiIconButton-edgeStart {
    margin-left: 0 !important;
}
#menu-appbar {
    z-index: 1000 !important;
}
#removeUser {
    width: 600px;
    max-width: 600px;
}