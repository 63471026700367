.appointment-start-time .react-datepicker__time-container {
	width: 200px;
}

.appointment-end-time .react-datepicker__time-container {
	width: 200px;
}
.appointment-start-time .react-datepicker-popper {
	width: 200px;
}
.appointment-end-time .react-datepicker-popper {
	width: 200px;
}
.react-datepicker__input-container {
	width: 100%;
}
