.container.custom {
    width: 100%;
    max-width: calc(100% - 8rem);
    margin-left: 120px;
    overflow-x: hidden;
    gap: 0;
}

.container.custom .sidebar {
    margin-right: 40px;
}

.container.custom .center_box_items {
    display: block;
    max-width: 640px;
    width: 100%;
    margin-right: 10px;
}

.center_box_items .box_item {
    border: 1px solid;
    border: 3px solid #7eb348;
    border-radius: 20px;
    padding: 5px 10px 50px;
    height: auto;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.top_box_content .top_title {
    color: #7eb348;
    font-size: 18px;
    margin-bottom: 30px;
}

.top_box_content .bottom_title {
    color: #7eb348;
    font-size: 18px;
    margin: 40px 0;
}

.top_box_content .list_items .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.top_box_content .list_items .item .right_item {
    font-weight: bold;
    color: #000000;
    font-size: 17px;
}

.top_box_content .list_items .item .left_item {
    color: #707070;
    font-size: 16px;
    font-weight: bold;
}

.right_box_item .box_item {
    border: 1px solid;
    border: 3px solid #7eb348;
    border-radius: 20px;
    padding: 5px 10px;
    height: auto;
    background-color: #ffffff;
    height: 100%;
}

.center_box_items .box_item:nth-child(2) {
    border: 1px solid;
    border: 3px solid #790000;
    border-radius: 20px;
    padding: 5px 10px 50px;
    height: auto;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 0px;
}

.bottom_box_content .top_title {
    color: #790000;
    font-size: 18px;
    margin-bottom: 30px;
}

.bottom_box_content .bottom_title {
    color: #790000;
    font-size: 18px;
    margin: 40px 0;
}

.bottom_box_content .list_items .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.bottom_box_content .list_items .item .right_item {
    font-weight: bold;
    color: #000000;
    font-size: 17px;
}

.bottom_box_content .list_items .item .left_item {
    color: #707070;
    font-size: 16px;
    font-weight: bold;
}

.container.custom .right_box_item {
    max-width: 350px;
    width: 100%;
}

.right_box_content .top_title {
    color: #7eb348;
    font-size: 18px;
    margin-bottom: 90px;
}

.right_box_content .bottom_title {
    color: #7eb348;
    font-size: 18px;
    margin: 90px 0;
}

.right_box_content .list_items .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.right_box_content .list_items .item .right_item {
    font-weight: bold;
    color: #000000;
    font-size: 17px;
}

.right_box_content .list_items .item .left_item {
    color: #707070;
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .container.custom {
        margin-left: 0;
        max-width: 100%;
    }
}