.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent !important;
    color: #e7426d;
}

li.MuiTreeItem-root.Mui-expanded.Mui-selected::before {
    content: '';
    content: '';
    position: absolute;
    border-color: #aeaeae;
    border-style: solid;
    border-width: 0px 0.2em 0.2em 0;
    height: 1.2em;
    top: 11.5em;
    left: 5.2em;
    margin-top: -1em;
    transform: rotate(0deg) rotateY(180deg);
    width: 1.5em;
}

.MuiTreeItem-label:hover {
    background-color: transparent !important;
}

.MuiTreeItem-root {
    padding-bottom: 10px;
}

.MuiCollapse-wrapperInner {
    width: 90%;
    padding-top: 5px;
    padding-left: 0;
}

.MuiCollapse-wrapperInner .MuiTreeItem-group {
    margin-left: -12px !important;
}

.MuiCollapse-wrapperInner .MuiTreeItem-label {
    color: #000 !important;
}

.border_none .p-dropdown {
    border: none !important;
}
