.sv_main .sv_body {
  font-family: 'Neutro';
  border-top: none;
}

.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: white;
}

.sv_main .sv_p_root > .sv_row {
  border-bottom: 0;
}

.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* button */
.space-align-block {
  position: absolute;
  right: 10%;
  top: 190px;
}
