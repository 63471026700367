/*.dropdownIndex, .p-dropdown-panel{*/
/*    z-index: 5555 !important;*/
/*}*/
@media (max-width: 1540px) {
  .MuiCardContent-root:last-child {
    padding-bottom: 0 !important;
  }
  .MuiCardContent-root {
    padding: 0 !important;
  }
  .MuiPickersBasePicker-container {
    flex-direction: row;
  }
  .MuiPickersToolbar-toolbar {
    height: auto;
  }
  .MuiStepper-root {
    padding: 4px 24px;
  }
}
