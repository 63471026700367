:root {
  --font-size: 0.975rem;
  --white: #ffffff;
}

* {
  font-family: 'PT Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.moduleAll {
  display: inline-grid;
  position: absolute;
}

.module-detail {
  padding-left: 100px;
}

body {
  margin: 0;
  font-size: 0.875rem !important;
  background: #f0f2f5;
  font-family: 'PT Sans Pro', sans-serif;
}

ul.ust_menu {
  /*margin-right: -29px;*/
  list-style: none;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding: 0;
  margin: 0;
}

ul.ust_menu li {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  min-width: 80px;
  border-left: 1px solid #f1f3f6;
  border-bottom: none;
  border-top: none;
  cursor: pointer;
  /*height: 65.3px;*/
}

ul.ust_menu li span {
  display: block;
  margin-top: 2px;
  color: black;
  font-weight: bolder;
}

ul.ust_menu li:hover {
  background-color: #f2f2f2;
}

.save-menu {
  font-size: 2.2rem;
  color: #30cbc4;
  padding-top: 7px;
}

body,
.p-component {
  font-size: var(--font-size) !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

/*.card {*/

/*    padding: 10px;*/

/*    background-color: white;*/

/*}*/

.card.card-custom {
  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);
  border: 0;
}

.gutter-b {
  margin-bottom: 25px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0.42rem;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card * a {
  text-decoration: none;
}

.card.card-custom > .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
}

.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  margin-bottom: 0;
  padding: 0.75rem 0 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.card.card-custom > .card-header .card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.card-title {
  margin-bottom: 2rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #181c32;
}

.card.card-custom > .card-body {
  padding: 2rem 2.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2.25rem;
}

.card-body {
  padding: 1.25rem;
}

.card.card-custom > .card-header .card-toolbar {
  display: flex;
  align-items: center;
  /*margin: 0.5rem 0;*/
  flex-wrap: wrap;
}

@font-face {
  font-family: 'PT Sans Pro';
  src: local('PT Sans Pro Regular'), local('PT-Sans-Pro-Regular'),
    url('fonts/PTSansPro-Regular.woff2') format('woff2'),
    url('fonts/PTSansPro-Regular.woff') format('woff'),
    url('fonts/PTSansPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.country span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

.country img.flag {
  width: 25px;
  margin-right: 5px;
}

.country .flag {
  background: url(assets/images/flags/flags_responsive.png) no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

.country .flag-ad {
  background-position: 0 0.413223%;
}

.country .flag-ae {
  background-position: 0 0.826446%;
}

.country .flag-af {
  background-position: 0 1.239669%;
}

.country .flag-ag {
  background-position: 0 1.652893%;
}

.country .flag-ai {
  background-position: 0 2.066116%;
}

.country .flag-al {
  background-position: 0 2.479339%;
}

.country .flag-am {
  background-position: 0 2.892562%;
}

.country .flag-an {
  background-position: 0 3.305785%;
}

.country .flag-ao {
  background-position: 0 3.719008%;
}

.country .flag-aq {
  background-position: 0 4.132231%;
}

.country .flag-ar {
  background-position: 0 4.545455%;
}

.country .flag-as {
  background-position: 0 4.958678%;
}

.country .flag-at {
  background-position: 0 5.371901%;
}

.country .flag-au {
  background-position: 0 5.785124%;
}

.country .flag-aw {
  background-position: 0 6.198347%;
}

.country .flag-az {
  background-position: 0 6.61157%;
}

.country .flag-ba {
  background-position: 0 7.024793%;
}

.country .flag-bb {
  background-position: 0 7.438017%;
}

.country .flag-bd {
  background-position: 0 7.85124%;
}

.country .flag-be {
  background-position: 0 8.264463%;
}

.country .flag-bf {
  background-position: 0 8.677686%;
}

.country .flag-bg {
  background-position: 0 9.090909%;
}

.country .flag-bh {
  background-position: 0 9.504132%;
}

.country .flag-bi {
  background-position: 0 9.917355%;
}

.country .flag-bj {
  background-position: 0 10.330579%;
}

.country .flag-bm {
  background-position: 0 10.743802%;
}

.country .flag-bn {
  background-position: 0 11.157025%;
}

.country .flag-bo {
  background-position: 0 11.570248%;
}

.country .flag-br {
  background-position: 0 11.983471%;
}

.country .flag-bs {
  background-position: 0 12.396694%;
}

.country .flag-bt {
  background-position: 0 12.809917%;
}

.country .flag-bv {
  background-position: 0 13.22314%;
}

.country .flag-bw {
  background-position: 0 13.636364%;
}

.country .flag-by {
  background-position: 0 14.049587%;
}

.country .flag-bz {
  background-position: 0 14.46281%;
}

.country .flag-ca {
  background-position: 0 14.876033%;
}

.country .flag-cc {
  background-position: 0 15.289256%;
}

.country .flag-cd {
  background-position: 0 15.702479%;
}

.country .flag-cf {
  background-position: 0 16.115702%;
}

.country .flag-cg {
  background-position: 0 16.528926%;
}

.country .flag-ch {
  background-position: 0 16.942149%;
}

.country .flag-ci {
  background-position: 0 17.355372%;
}

.country .flag-ck {
  background-position: 0 17.768595%;
}

.country .flag-cl {
  background-position: 0 18.181818%;
}

.country .flag-cm {
  background-position: 0 18.595041%;
}

.country .flag-cn {
  background-position: 0 19.008264%;
}

.country .flag-co {
  background-position: 0 19.421488%;
}

.country .flag-cr {
  background-position: 0 19.834711%;
}

.country .flag-cu {
  background-position: 0 20.247934%;
}

.country .flag-cv {
  background-position: 0 20.661157%;
}

.country .flag-cx {
  background-position: 0 21.07438%;
}

.country .flag-cy {
  background-position: 0 21.487603%;
}

.country .flag-cz {
  background-position: 0 21.900826%;
}

.country .flag-de {
  background-position: 0 22.31405%;
}

.country .flag-dj {
  background-position: 0 22.727273%;
}

.country .flag-dk {
  background-position: 0 23.140496%;
}

.country .flag-dm {
  background-position: 0 23.553719%;
}

.country .flag-do {
  background-position: 0 23.966942%;
}

.country .flag-dz {
  background-position: 0 24.380165%;
}

.country .flag-ec {
  background-position: 0 24.793388%;
}

.country .flag-ee {
  background-position: 0 25.206612%;
}

.country .flag-eg {
  background-position: 0 25.619835%;
}

.country .flag-eh {
  background-position: 0 26.033058%;
}

.country .flag-er {
  background-position: 0 26.446281%;
}

.country .flag-es {
  background-position: 0 26.859504%;
}

.country .flag-et {
  background-position: 0 27.272727%;
}

.country .flag-fi {
  background-position: 0 27.68595%;
}

.country .flag-fj {
  background-position: 0 28.099174%;
}

.country .flag-fk {
  background-position: 0 28.512397%;
}

.country .flag-fm {
  background-position: 0 28.92562%;
}

.country .flag-fo {
  background-position: 0 29.338843%;
}

.country .flag-fr {
  background-position: 0 29.752066%;
}

.country .flag-ga {
  background-position: 0 30.165289%;
}

.country .flag-gd {
  background-position: 0 30.578512%;
}

.country .flag-ge {
  background-position: 0 30.991736%;
}

.country .flag-gf {
  background-position: 0 31.404959%;
}

.country .flag-gh {
  background-position: 0 31.818182%;
}

.country .flag-gi {
  background-position: 0 32.231405%;
}

.country .flag-gl {
  background-position: 0 32.644628%;
}

.country .flag-gm {
  background-position: 0 33.057851%;
}

.country .flag-gn {
  background-position: 0 33.471074%;
}

.country .flag-gp {
  background-position: 0 33.884298%;
}

.country .flag-gq {
  background-position: 0 34.297521%;
}

.country .flag-gr {
  background-position: 0 34.710744%;
}

.country .flag-gs {
  background-position: 0 35.123967%;
}

.country .flag-gt {
  background-position: 0 35.53719%;
}

.country .flag-gu {
  background-position: 0 35.950413%;
}

.country .flag-gw {
  background-position: 0 36.363636%;
}

.country .flag-gy {
  background-position: 0 36.77686%;
}

.country .flag-hk {
  background-position: 0 37.190083%;
}

.country .flag-hm {
  background-position: 0 37.603306%;
}

.country .flag-hn {
  background-position: 0 38.016529%;
}

.country .flag-hr {
  background-position: 0 38.429752%;
}

.country .flag-ht {
  background-position: 0 38.842975%;
}

.country .flag-hu {
  background-position: 0 39.256198%;
}

.country .flag-id {
  background-position: 0 39.669421%;
}

.country .flag-ie {
  background-position: 0 40.082645%;
}

.country .flag-il {
  background-position: 0 40.495868%;
}

.country .flag-in {
  background-position: 0 40.909091%;
}

.country .flag-io {
  background-position: 0 41.322314%;
}

.country .flag-iq {
  background-position: 0 41.735537%;
}

.country .flag-ir {
  background-position: 0 42.14876%;
}

.country .flag-is {
  background-position: 0 42.561983%;
}

.country .flag-it {
  background-position: 0 42.975207%;
}

.country .flag-jm {
  background-position: 0 43.38843%;
}

.country .flag-jo {
  background-position: 0 43.801653%;
}

.country .flag-jp {
  background-position: 0 44.214876%;
}

.country .flag-ke {
  background-position: 0 44.628099%;
}

.country .flag-kg {
  background-position: 0 45.041322%;
}

.country .flag-kh {
  background-position: 0 45.454545%;
}

.country .flag-ki {
  background-position: 0 45.867769%;
}

.country .flag-km {
  background-position: 0 46.280992%;
}

.country .flag-kn {
  background-position: 0 46.694215%;
}

.country .flag-kp {
  background-position: 0 47.107438%;
}

.country .flag-kr {
  background-position: 0 47.520661%;
}

.country .flag-kw {
  background-position: 0 47.933884%;
}

.country .flag-ky {
  background-position: 0 48.347107%;
}

.country .flag-kz {
  background-position: 0 48.760331%;
}

.country .flag-la {
  background-position: 0 49.173554%;
}

.country .flag-lb {
  background-position: 0 49.586777%;
}

.country .flag-lc {
  background-position: 0 50%;
}

.country .flag-li {
  background-position: 0 50.413223%;
}

.country .flag-lk {
  background-position: 0 50.826446%;
}

.country .flag-lr {
  background-position: 0 51.239669%;
}

.country .flag-ls {
  background-position: 0 51.652893%;
}

.country .flag-lt {
  background-position: 0 52.066116%;
}

.country .flag-lu {
  background-position: 0 52.479339%;
}

.country .flag-lv {
  background-position: 0 52.892562%;
}

.country .flag-ly {
  background-position: 0 53.305785%;
}

.country .flag-ma {
  background-position: 0 53.719008%;
}

.country .flag-mc {
  background-position: 0 54.132231%;
}

.country .flag-md {
  background-position: 0 54.545455%;
}

.country .flag-me {
  background-position: 0 54.958678%;
}

.country .flag-mg {
  background-position: 0 55.371901%;
}

.country .flag-mh {
  background-position: 0 55.785124%;
}

.country .flag-mk {
  background-position: 0 56.198347%;
}

.country .flag-ml {
  background-position: 0 56.61157%;
}

.country .flag-mm {
  background-position: 0 57.024793%;
}

.country .flag-mn {
  background-position: 0 57.438017%;
}

.country .flag-mo {
  background-position: 0 57.85124%;
}

.country .flag-mp {
  background-position: 0 58.264463%;
}

.country .flag-mq {
  background-position: 0 58.677686%;
}

.country .flag-mr {
  background-position: 0 59.090909%;
}

.country .flag-ms {
  background-position: 0 59.504132%;
}

.country .flag-mt {
  background-position: 0 59.917355%;
}

.country .flag-mu {
  background-position: 0 60.330579%;
}

.country .flag-mv {
  background-position: 0 60.743802%;
}

.country .flag-mw {
  background-position: 0 61.157025%;
}

.country .flag-mx {
  background-position: 0 61.570248%;
}

.country .flag-my {
  background-position: 0 61.983471%;
}

.country .flag-mz {
  background-position: 0 62.396694%;
}

.country .flag-na {
  background-position: 0 62.809917%;
}

.country .flag-nc {
  background-position: 0 63.22314%;
}

.country .flag-ne {
  background-position: 0 63.636364%;
}

.country .flag-nf {
  background-position: 0 64.049587%;
}

.country .flag-ng {
  background-position: 0 64.46281%;
}

.country .flag-ni {
  background-position: 0 64.876033%;
}

.country .flag-nl {
  background-position: 0 65.289256%;
}

.country .flag-no {
  background-position: 0 65.702479%;
}

.country .flag-np {
  background-position: 0 66.115702%;
}

.country .flag-nr {
  background-position: 0 66.528926%;
}

.country .flag-nu {
  background-position: 0 66.942149%;
}

.country .flag-nz {
  background-position: 0 67.355372%;
}

.country .flag-om {
  background-position: 0 67.768595%;
}

.country .flag-pa {
  background-position: 0 68.181818%;
}

.country .flag-pe {
  background-position: 0 68.595041%;
}

.country .flag-pf {
  background-position: 0 69.008264%;
}

.country .flag-pg {
  background-position: 0 69.421488%;
}

.country .flag-ph {
  background-position: 0 69.834711%;
}

.country .flag-pk {
  background-position: 0 70.247934%;
}

.country .flag-pl {
  background-position: 0 70.661157%;
}

.country .flag-pm {
  background-position: 0 71.07438%;
}

.country .flag-pn {
  background-position: 0 71.487603%;
}

.country .flag-pr {
  background-position: 0 71.900826%;
}

.country .flag-pt {
  background-position: 0 72.31405%;
}

.country .flag-pw {
  background-position: 0 72.727273%;
}

.country .flag-py {
  background-position: 0 73.140496%;
}

.country .flag-qa {
  background-position: 0 73.553719%;
}

.country .flag-re {
  background-position: 0 73.966942%;
}

.country .flag-ro {
  background-position: 0 74.380165%;
}

.country .flag-rs {
  background-position: 0 74.793388%;
}

.country .flag-ru {
  background-position: 0 75.206612%;
}

.country .flag-rw {
  background-position: 0 75.619835%;
}

.country .flag-sa {
  background-position: 0 76.033058%;
}

.country .flag-sb {
  background-position: 0 76.446281%;
}

.country .flag-sc {
  background-position: 0 76.859504%;
}

.country .flag-sd {
  background-position: 0 77.272727%;
}

.country .flag-se {
  background-position: 0 77.68595%;
}

.country .flag-sg {
  background-position: 0 78.099174%;
}

.country .flag-sh {
  background-position: 0 78.512397%;
}

.country .flag-si {
  background-position: 0 78.92562%;
}

.country .flag-sj {
  background-position: 0 79.338843%;
}

.country .flag-sk {
  background-position: 0 79.752066%;
}

.country .flag-sl {
  background-position: 0 80.165289%;
}

.country .flag-sm {
  background-position: 0 80.578512%;
}

.country .flag-sn {
  background-position: 0 80.991736%;
}

.country .flag-so {
  background-position: 0 81.404959%;
}

.country .flag-sr {
  background-position: 0 81.818182%;
}

.country .flag-ss {
  background-position: 0 82.231405%;
}

.country .flag-st {
  background-position: 0 82.644628%;
}

.country .flag-sv {
  background-position: 0 83.057851%;
}

.country .flag-sy {
  background-position: 0 83.471074%;
}

.country .flag-sz {
  background-position: 0 83.884298%;
}

.country .flag-tc {
  background-position: 0 84.297521%;
}

.country .flag-td {
  background-position: 0 84.710744%;
}

.country .flag-tf {
  background-position: 0 85.123967%;
}

.country .flag-tg {
  background-position: 0 85.53719%;
}

.country .flag-th {
  background-position: 0 85.950413%;
}

.country .flag-tj {
  background-position: 0 86.363636%;
}

.country .flag-tk {
  background-position: 0 86.77686%;
}

.country .flag-tl {
  background-position: 0 87.190083%;
}

.country .flag-tm {
  background-position: 0 87.603306%;
}

.country .flag-tn {
  background-position: 0 88.016529%;
}

.country .flag-to {
  background-position: 0 88.429752%;
}

.country .flag-tp {
  background-position: 0 88.842975%;
}

.country .flag-tr {
  background-position: 0 89.256198%;
}

.country .flag-tt {
  background-position: 0 89.669421%;
}

.country .flag-tv {
  background-position: 0 90.082645%;
}

.country .flag-tw {
  background-position: 0 90.495868%;
}

.country .flag-ty {
  background-position: 0 90.909091%;
}

.country .flag-tz {
  background-position: 0 91.322314%;
}

.country .flag-ua {
  background-position: 0 91.735537%;
}

.country .flag-ug {
  background-position: 0 92.14876%;
}

.country .flag-gb,
.country .flag-uk {
  background-position: 0 92.561983%;
}

.country .flag-um {
  background-position: 0 92.975207%;
}

.country .flag-us {
  background-position: 0 93.38843%;
}

.country .flag-uy {
  background-position: 0 93.801653%;
}

.country .flag-uz {
  background-position: 0 94.214876%;
}

.country .flag-va {
  background-position: 0 94.628099%;
}

.country .flag-vc {
  background-position: 0 95.041322%;
}

.country .flag-ve {
  background-position: 0 95.454545%;
}

.country .flag-vg {
  background-position: 0 95.867769%;
}

.country .flag-vi {
  background-position: 0 96.280992%;
}

.country .flag-vn {
  background-position: 0 96.694215%;
}

.country .flag-vu {
  background-position: 0 97.107438%;
}

.country .flag-wf {
  background-position: 0 97.520661%;
}

.country .flag-ws {
  background-position: 0 97.933884%;
}

.country .flag-ye {
  background-position: 0 98.347107%;
}

.country .flag-za {
  background-position: 0 98.760331%;
}

.country .flag-zm {
  background-position: 0 99.173554%;
}

.country .flag-zr {
  background-position: 0 99.586777%;
}

.country .flag-zw {
  background-position: 0 100%;
}

.country .country-item {
  display: flex;
  align-items: center;
}
.countryFlagLocation .country-item {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0.6rem;
}

.country .country-item img.country .flag {
  width: 18px;
  margin-right: 0.5rem;
}
.d-none {
  display: none;
}
