.react-datepicker {
	font-family: 'Neutro';
	font-size: 14px;
}

.react-datepicker__header {
	background-color: #fff;
}
.react-datepicker-popper[data-placement^='top'] {
	padding-bottom: 0px;
}

.react-datepicker__day {
	border-radius: 0;
}

.react-datepicker__day:hover {
	border-radius: 0;
}

.react-datepicker__day--in-range {
	background-color: #cdecff;
}

.react-datepicker__day--in-range:hover {
	background-color: #009dff;
}

.react-datepicker__day--in-selecting-range {
	background-color: #25a8fa !important;
}

.react-datepicker__day--range-start {
	background-color: #0099f7;
}

.react-datepicker__day--range-end {
	background-color: #0099f7;
}
.react-datepicker__month-read-view--down-arrow {
	display: block;
	position: absolute;
	top: 15%;
}
.react-datepicker__month-read-view {
	visibility: visible !important;
}
.react-datepicker__year-read-view {
	visibility: visible !important;
}
.react-datepicker__month-dropdown {
	position: absolute;
	top: 65%;
	left: 5%;
	height: 150px;
	overflow-y: scroll;
}
.react-datepicker__year-dropdown {
	position: absolute;
	top: 80%;
	right: 0%;
}
.react-datepicker__year-dropdown--scrollable {
	position: absolute;
	top: 65%;
	left: 45%;
}
.react-datepicker__year-read-view--down-arrow {
	display: block;
	position: absolute;
	top: 15%;
}
.react-datepicker__header__dropdown {
	display: flex;
	justify-content: space-around;
}
.react-datepicker__month-dropdown-container {
	border: #a5a9ac solid 1px;
	align-items: center;
	padding-right: 25px;
	padding-left: 5px;
	margin-left: 25px;
	font-weight: bold;
}
.react-datepicker__year-dropdown-container {
	border: #a5a9ac solid 1px;
	align-items: center;
	padding-right: 25px;
	padding-left: 5px;
	margin-right: 25px;
}
