#chartdiv {
  width: 100%;
  height: 700px;
}
.reviews #chartdiv {
  position: relative;
  top: 32px;
}
.gray {
  color: #cccccc;
}
.bold {
  color: #000000;
  font-weight: bolder;
}
/* .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  background-color: #46c5e9 !important;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  background-color: #657079 !important;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header > a {
  background-color: #de2348 !important;
} */
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border: none !important;
  border-radius: 12px !important;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  position: absolute;
  right: 0;
  color: #fff;
}
.p-panelmenu .p-menuitem-text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 12px;
  border: none;
}

li.p-menuitem {
  background: #f9fafb;
  margin: 6px 0;
  border-radius: 6px;
}
.p-panelmenu .p-panelmenu-content {
  border: none !important;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 2rem;
  color: #000000;
}

.container .reviews {
  max-width: 869px;
  width: 100%;
}

/* .container {
  justify-content: space-evenly;
} */

.p-accordion .p-accordion-tab {
  margin-bottom: 6px;
  max-width: 100%;
  margin: 0 auto;
}

.p-accordion-header {
  padding: 0.4rem 1rem;
}

.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  background: #d3d8dc;
  /* transform: skew(-20deg); */
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  background: #d3d8dc;
  /* transform: skew(-20deg); */
}
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link
  .p-accordion-header-text {
  /* transform: skew(20deg); */
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: #d3d8dc;
}
.p-accordion-tab.p-accordion-tab-active
  .p-accordion
  .p-accordion-header
  .p-accordion-header-link {
  padding: 0.6rem !important;
}
.p-accordion-tab.p-accordion-tab-active
  .p-accordion-tab.p-accordion-tab-active {
}
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  display: none;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  margin-top: 10px;
  border: none !important;
  background: transparent !important;
}

.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  z-index: 0;
}
.overviewAccordion {
  flex: 1;
  max-width: 1150px;
  width: 100%;
  top: 126px;
  position: relative;
  overflow-y: scroll;
  max-height: 700px;
  /* scroll-behavior: smooth; */
}
.overviewAccordion::-webkit-scrollbar {
  width: 2px !important;
}
.overviewAccordion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.overviewAccordion::-webkit-scrollbar-thumb {
  background: rgb(87, 80, 80);
  border-radius: 10px;
}
.mainHeader {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
  width: 1130px;
  align-items: center;
  top: 61px;
  background: #f0f2f5;
  border-radius: 10px;
  padding: 0;
}
.reviews .chartTitle {
  font-size: 24px;
  color: #001e57;
  margin: 0;
}
.point-table {
  width: 100%;
  height: auto;
  max-width: 600px;
  /* margin: 0 auto 40px; */
  display: flex;
  padding: 4px 12px 2px 6px;
}

.point-table .title {
  background: #0099f7;
  flex-basis: 60%;
  /* transform: skew(-20deg); */
  margin-left: 12px;
}
.point-table .title h3 {
  color: #fff;
  font-size: 16px;
  line-height: 1.7;
  /* transform: skew(20deg); */
  padding: 0 14px;
}
.point-table .score {
  background-color: #8bdff4;
  color: #001e57;
  /* transform: skew(-20deg); */
  flex-basis: 40%;
}
.point-table .score div {
  /* transform: skew(20deg); */
  display: flex;
  height: 100%;
  justify-content: center;
  gap: 24px;
  align-items: center;
}
.p-accordion-tab.p-accordion-tab-active {
  height: 100%;
}
.point-table .score div span {
  font-size: 16px;
}

.point-table .score span.strong {
  font-size: 20px;
  font-weight: bold;
}

.mainHeader .colorsRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  position: relative;
  top: 10px;
}
.mainHeader .colorsRow .colorItem {
  max-width: 60px;
}
.mainHeader .colorsRow .colorItem.column-reverse {
  flex-flow: column-reverse wrap;
}
.mainHeader .colorsRow.reverse {
  align-items: baseline;
}
.mainHeader .colorsRow .color {
  width: 60px;
  height: 40px;
  /* transform: skew(-20deg); */
}

.mainHeader .colorsRow .colorItem .text {
  font-size: 11px;
}
.p-tooltip .p-tooltip-text {
  background-color: rgba(255, 255, 255, 0.85);
  color: #000000;
  border-radius: 12px;
}

.p-panelmenu .p-submenu-list {
  max-height: 300px;
  overflow-y: scroll;
}
.p-panelmenu .p-submenu-list::-webkit-scrollbar {
  width: 2px;
}
.p-panelmenu .p-submenu-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.p-panelmenu .p-submenu-list::-webkit-scrollbar-thumb {
  background: rgb(87, 80, 80);
  border-radius: 10px;
}

#triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid rgb(149, 193, 31);
  position: relative;
  left: 16px;
  top: -24px;
}

.p-accordion-header-text-0::before {
}
/* .topAccordionHeader {
  border-left: 6px solid #46c5e9;
  padding: 0 0;
  margin: 0.4rem 1rem;
} */
/* .topAccordionHeader {
  border-left: 5px solid #178344;
} */
.topAccordionTitle {
  text-align: center;
  position: relative;
}

.topAccordionTitle h2 {
  width: 100%;
  color: #001e57;
  font-size: 22;
}

.topAccordionTitle:before {
  content: '';
  background: #d3d8dc;
  width: 34%;
  height: 2px;
  position: absolute;
  top: 16px;
  left: 18px;
}

.topAccordionTitle:after {
  content: '';
  background: #d3d8dc;
  width: 34%;
  height: 2px;
  position: absolute;
  top: 16px;
  right: 18px;
}

.bottomAccordionTitle {
  text-align: center;
  position: relative;
}

.bottomAccordionTitle h2 {
  width: 100%;
  color: #001e57;
  font-size: 22;
}

.bottomAccordionTitle:before {
  content: '';
  background: #d3d8dc;
  width: 34%;
  height: 2px;
  position: absolute;
  top: 16px;
  left: 18px;
}

.bottomAccordionTitle:after {
  content: '';
  background: #d3d8dc;
  width: 34%;
  height: 2px;
  position: absolute;
  top: 16px;
  right: 18px;
}
.p-accordion-header.topAccordionHeader {
  position: relative;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header.expertReview.border1:not(.p-highlight)
  .p-accordion-header-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgb(146, 158, 168);
  transition: all 0.5s;
  animation: clippath 3s infinite linear;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header.expertReview.border2:not(.p-highlight)
  .p-accordion-header-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgb(0, 153, 247);
  transition: all 0.5s;
  animation: clippath 3s infinite linear;
}
.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header.expertReview.border3:not(.p-highlight)
  .p-accordion-header-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgb(222, 35, 72);
  transition: all 0.5s;
  animation: clippath 3s infinite linear;
}
@keyframes clippath {
  0%,
  100% {
    clip-path: inset(100% 0 0 0);
  }
  25% {
    clip-path: inset(0 100% 0 0);
  }
  50% {
    clip-path: inset(0 0 100% 0);
  }
  75% {
    clip-path: inset(0 0 0 100%);
  }
}
.p-accordion-content p.metric-warning {
  position: relative;
  top: -43px;
  left: 10px;
}
.container-metric-module {
  position: relative;
  top: -20px;
}
.container-metric-module .subdomainName {
  color: #001e57;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  position: relative;
  width: fit-content;
}
.container-metric-module .subdomainName::before {
  content: '';
  width: 7px;
  height: 35px;
  background-color: #de2348;
  position: absolute;
  top: -5px;
  left: -21px;
}

.container-metric-module .domainList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-column-gap: 20px;
  column-gap: 20px;
}

.container-metric-module .domainList > .metric-flexItems {
  line-height: 2.8;
  display: flex;
  list-style-position: inside;
  justify-content: space-between;
  /* margin-left: 5px; */
  /* flex: 1 0 300px; */
  border: 1px solid #bfc5cb;
  margin: 5px 0;
}
.container-metric-module .domainList > .metric-flexItems .friendlyName {
  margin-left: 10px;
  word-break: break-word;
}
.container-metric-module .domainList > .metric-flexItems .metricResult {
  background-color: #d2d6d9;
  color: #001e57;
  min-width: 120px;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 1080px) {
  .overviewAccordion {
    max-height: 460px;
  }
}
@media (min-width: 1280px) {
  .overviewAccordion {
    max-height: 500px;
  }
}
@media (min-width: 1400px) {
  .overviewAccordion {
    max-height: 600px;
  }
}
@media (min-width: 1600px) {
  .overviewAccordion {
    max-height: 700px;
  }
}
.appointmentIconAction {
  display: flex;
}
